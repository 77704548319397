<template>
  <v-col cols="12"
         sm="6"
         md="4"
         xl="12"
         >

    <v-card
        class="mx-auto mt-5 rounded-xl"
        elevation="10"
        align="center"
        max-width="350"
    >
      <v-card-text class="px-14">
        <p class="headertitle">
          {{items.name}}
        </p>

        <div
            class="pa-4 bg-secondary rounded-circle d-inline-block "
        >
          <v-icon color="white" size="55">mdi-whatsapp</v-icon>
        </div>
        <div class="d-flex justify-center align-content-start my-3" >
          <p class="text-h6  font-weight-bold mt-9 px-2">
            {{items.expired_date==30?'month':items.expired_date+'days'}}/
          </p>

          <p class="text-h3 text--primary font-weight-bold mt-5">
           {{items.price}}$
          </p>

        </div>

        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green">check_circle</v-icon>
          <p class="description mt-4">
            {{items.quota_type=="limited"?" عدد الرسائل "+items.quota:'رسائل غير محدودة'}}
          </p>


        </v-row>
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green">check_circle</v-icon>
          <p class="description mt-4">
            ارسال رسائل نصية
          </p>


        </v-row>
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green" v-if="items.is_relay===true">check_circle</v-icon>
          <v-icon class="ml-4" v-else color="red" >cancel</v-icon>
          <p class="description mt-4">
            الرد الالي
          </p>

        </v-row>
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green" v-if="items.is_send_group===true">check_circle</v-icon>
          <v-icon class="ml-4" v-else color="red" >cancel</v-icon>
          <p class="description mt-4">
            رسائل للمجموعات
          </p>

        </v-row>
   
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green" v-if="items.is_send_image===true">check_circle</v-icon>
          <v-icon class="ml-4" v-else color="red" >cancel</v-icon>
          <p class="description mt-4">
            رسائل صور
          </p>

        </v-row>
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green" v-if="items.is_send_video===true">check_circle</v-icon>
          <v-icon class="ml-4" v-else color="red" >cancel</v-icon>
          <p class="description mt-4">
            رسائل فيديوهات
          </p>

        </v-row>
        <v-row justify="start" align="center">
          <v-icon class="ml-4" color="green" v-if="items.is_send_pdf===true">check_circle</v-icon>
          <v-icon class="ml-4" v-else color="red" >cancel</v-icon>
          <p class="description mt-4">
            رسائل ملفات
          </p>


        </v-row>



        <v-divider/>
      </v-card-text>

      <v-card-actions   class="d-flex justify-center pb-8" >
        <v-btn
            v-if="$store.getters.hasCreatePermission('request')"
            dark
            style="  background-color: #00cc86;"
            width="80%"
            @click="SendRequest"
            class=" rounded-xl py-6 "
        >

         اطلب الان
        </v-btn>
      </v-card-actions>
    <SendRequestPackage :dialog-controller.sync="dialogController" v-if="dialogController" :product_serial="items.serial"  :items-devices="devices"/>
    </v-card>
  </v-col>

</template>

<script>
import SendRequestPackage from "@/components/SendRequestPackage";
export default {
  components:{
    SendRequestPackage
  },
  props:["items","devices"],
  data: () => ({
    dialogController:false,
  }),

  methods:{
    SendRequest(){

      this.dialogController = true;
    }
  }
}


</script>

<style scoped>
.rounded-circle{
  background-color: #00cc86;
}
.description{
  font-size: 1.2rem;
}
.headertitle{
  font-size: 1.3rem;
  font-weight: bold;
  color:black;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>