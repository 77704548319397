<template>
  <div class="text-center">
    <Loading v-if="isloading" dialog="isloading" title="جاري التنفيذ"/>
    <v-dialog
        v-model="dialogController"
        persistent
        max-width="290"
    >
      <v-card>

        <v-card-title class="text-h5">
         هل تريد {{isAprove?"الموافقة":"الرفض"}}؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click.native="close"
          >
            الغاء
          </v-btn>

          <v-btn

              color="green darken-1"
              @click="editItem"
              text>

            {{isAprove?"موافقة":"رفض"}}
          </v-btn>

        </v-card-actions>
      </v-card>

      <v-alert
          class="mt-5"
          left
          border="left"
          color="red"
          dismissible
          elevation="9"
          type="error"
          v-model="isShow"
      >{{msgError}}</v-alert>
      <SuccessDialog :title="msgSucess"  v-if="successDialog" :success-dialog.sync="successDialog" />
    </v-dialog>
  </div>
</template>
<script>
import SuccessDialog from '@/components/dialogs/SucessSended.vue';
import Loading from '@/components/Loading.vue';
import Groupview from "@/components/dialogs/Groupview";
import PushServicesSocket from "@/services/PushServicesSocket";
export default {

  props:{
    itemDelete:{},
    urlName:String,
    token:String,

    isAprove:Boolean,
    dialogController: {
      default: false
    },
  },
  data () {
    return {

      isloading: false,
      isShow: false,
      successDialog: false,
      msgSucess:` تمت ${this.isAprove?" رفض ":" الموافقة "} بنجاح `,
      msgError:"فشل العملية ",

    }
  },
  components:{
    Loading,
    SuccessDialog
  },

  methods:{
    async editItem() {

      try {

        this.isloading =true;

        console.log(this.itemDelete.id)
        let body = {
          is_approve:this.isAprove?"approve":"rejecte"
        };
        const response = await PushServicesSocket.aproveItem(this.urlName,this.itemDelete.id,body,this.token);

        if(response.status)
        {

          this.successDialog = true;
          this.isloading = false;
          setTimeout(() => {
            this.close();
          },2000);

        }else{
          this.msgError = response.message;
          this.isShow = true;
          this.isloading =false;

        }


      } catch (error) {
        this.isShow = true;
        this.isloading =false;
      }
    },
    close() {
      this.$emit('update:dialogController', false);
      this.$root.$emit('closeGroupView');
      this.$root.$emit('updateGroupView');
      this.$root.$emit('closeView');
      this.$root.$emit('updateView');



    },
  }

}
</script>

<style scoped>


</style>