<template>
  <v-tabs
      color="deep-purple accent-4"
      centered
  >


    <v-tab v-if="$store.getters.hasViewPermission('product')">عروض الباقات</v-tab>

    <v-tab v-if="$store.getters.hasViewPermission('request')">الطلبات</v-tab>

    <v-tab-item>
      <Loading v-if="isloading"/>
      <div class="Packages pa-5" v-else>
        <v-row justify="center">
          <PriceCard v-for="(item, i) in contentItems" :key="i" :items="item" :devices="contentDevices"/>
        </v-row>
      </div>
    </v-tab-item>
    <v-tab-item>
      <Loading v-if="isloading"/>
      <v-container v-else>
        <v-row>
          <v-card-title>
            طلبات الباقات
          </v-card-title>
          <v-btn
              icon
              color="blue"
              class="pa-10"
              @click="getRequestAll()"

          >
            <v-icon size="40px">mdi-cached</v-icon>
          </v-btn>

        </v-row>
        <ActivationView :headers="headersActive" :contents="requestItems" :package-item="contentItems" :users="contentUsers"/>
      </v-container>
    </v-tab-item>


  </v-tabs>

</template>

<script>
import PriceCard from "@/components/PriceCard";
import ActivationView from '../components/RequestView.vue';
import Loading from '@/components/Loading.vue';
import PushServicesSocket from "@/services/PushServicesSocket";
    export default {
      components: {
        PriceCard,
        ActivationView,
        Loading
      },
      data: ()=>({
        contentItems:{},
        contentDevices:{},
        contentUsers:null,
        requestItems:{},
        isloading:true,

        headersActive: [
          {
            text: "تاريخ الطلب",
            align: "start",
            value: "timestamp",

          },
          { text: "مقدم الطلب", value: "username" },
          { text: "اسم الباقة", value: "pname" },
          { text: "حالة الطلب", value: "status" },
          { text: "الجهاز", value: "dserial" },
          { text: "الاجراء", value: "edit" },
        ],
      }),
      async created() {
        if(this.$store.getters.hasViewPermission('product'))
        await this.getAll();
        if(this.$store.getters.hasViewPermission('request'))
        await this.getRequestAll();
        if(this.$store.getters.hasViewPermission('device'))
        await this.getAllDevice();
      },

      methods: {

        async getAll(){
          this.isloading = true;

          this. contentItems = await PushServicesSocket.GetData("/product/getall");
          console.log(this.contentItems)
          if(this.contentItems.status)
          {
            this.contentItems = this.contentItems.data;
            this.contentItems.forEach(value =>{
              value.id = value.serial;

              return value;
            });
          }

          this.isloading = false;
        },
        async getRequestAll(){
          this.isloading = true;
          this. requestItems = await PushServicesSocket.GetData("/request/getall");
          if(this.requestItems.status)
          {
            this.requestItems = this.requestItems.data;
            this.requestItems.forEach(value =>{
              value.id = value.rserial;
              value.timestamp = value.timestamp.substring(0,16);
              return value;
            });
          }

          this.isloading = false;
        },
        async getAllDevice(){
          this.isloading = true;
          this. contentDevices = await PushServicesSocket.GetData("/device/getall");
          if( this.contentDevices.status===true)
          {
            this.contentDevices = this.contentDevices.data;

          }

          this.isloading = false;
        },
      },
      mounted() {
        this.$root.$on('updateView', () => {
          this.getRequestAll();
          this.getAll();
        });
      }

    }
</script>

<style scoped>

</style>