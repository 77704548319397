<template>
  <div>

    <Loading v-if="isloading" dialog="isloading" title="جاري تقديم الطلب"/>
    <v-dialog
        v-model="dialogController"
        persistent
        max-width="500"
    >
      <v-card elevation="0">
        <v-card-title>
          <span class="text-h5">ارسال طلب شراء باقة</span>
        </v-card-title>
        <v-card-text>
          <v-container >
            <v-row >
              <v-col>
                <v-card ref="form" elevation="0">
                  <v-card-text>
                    <v-form v-model="isFormValid">

                      <v-select
                          ref="inputRef"
                          dense
                          filled
                          no-data-text="الاجهزة غير متصلة او الرصيد غير كافي "
                          outlined
                          label="الجهاز"
                          solo
                          v-model="select"
                          @input="setSelected"
                          hide-selected
                          :rules="[() => !!select || 'يجب اختيار الجهاز']"
                          :items="itemsDevices"
                          clearable
                          item-value="serial"
                          :item-text="item => item.number+' ::: '+item.name"
                      ></v-select>
                      <v-alert

                          left
                          border="left"
                          color="red"
                          dismissible
                          elevation="9"
                          type="error"
                          v-model="isShow"
                      >{{msgError}}</v-alert>

                    </v-form>

                  </v-card-text>
                </v-card>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2"
                         width="20%"
                         outlined
                         color="paink"

                         @click="close"
                  >
                    الغاء
                  </v-btn>
                  <v-btn class="ma-2"
                         width="20%"
                         outlined
                         color="green"
                         :disabled="!isFormValid"
                         @click="sendRequest"
                  >
                    ارسال الطلب
                  </v-btn>

                </v-row>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>


      </v-card>
    </v-dialog>

    <SuccessDialog title="تم ارسال الطلب بنجاح!"  v-if="successDialog" :success-dialog.sync="successDialog" />




  </div>

</template>

<script>

import Loading from '@/components/LoadingDialog.vue';
import PushServicesSocket from '@/services/PushServicesSocket.js';
import SuccessDialog from '@/components/dialogs/SucessSended.vue';

export default {
  components: {
    Loading,
    SuccessDialog

  },
  props:[
    "itemsDevices",
      "product_serial",
      "dialogController"
  ],
  data () {
    return {


      isFormValid: false,
      select: "",
      selectToken: "",
      isloading: false,

      isShow: false,
      successDialog: false,
      msgSucess:"تم ارسال الرسالة بنجاح بنحاح",
      msgError:"فشل في الارسال",
      url: null,
      image: null,
    }
  },

  methods: {

    async sendRequest(){

        try {

          this.isloading =true;

          const values = {
            device_serial:this.selectToken,
            product_serial:this.product_serial,
          };


          const response = await PushServicesSocket.GetPostData("/request/create",values);
          if(response.status)
          {
            this.successDialog = true;
            this.isloading = false;
            this.isShow = false;

            setTimeout(() => {
              this.close();
            }, 3000);

          }else{
            this.msgError = response.message;
            this.isShow = true;
            this.isloading =false;
          }
        } catch (error) {
          this.isShow = true;
          this.isloading =false;
        }

    },
    async setSelected(value){

      this.selectToken = value;


    },
    close() {
      this.$emit('update:dialogController', false);
    },


  },

}
</script>

<style scoped>


</style>