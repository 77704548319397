<template>
  <v-card class="card" elevation="0">

    <Loading v-if="isloading"/>
    <v-data-table
        v-else
      :headers="headers"
      :items="contents"
      :search="search"
      sort-by="timestamp"
      :sort-desc="true"
      @click:row="handleClick">
      class="elevation-1 table">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
            ></v-text-field
            ></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
          >

            <v-card>
              <v-card-title class="text-h5">
               هل تريد  تفعيل باقة؟
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  الغاء
                </v-btn>

                <v-btn

                    color="green darken-1"
                    @click="openDialogNew()"
                    text>
                  التالي

                </v-btn>

              </v-card-actions>
            </v-card>
          </v-dialog>
          <DeleteItemDialog  :url-name="'/request/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="$store.state.token"/>

<!--                  <Accountview  v-if="qrDialog"  :qr-dialog.sync="qrDialog" :itemSelected="itemSelected" :headers="headers" :item-token="selectValue"/>-->
        </v-toolbar>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
            :color="getColor(item.status)"
            dark
        >
          {{ item.status}}
        </v-chip>
      </template>
      <template v-slot:item.edit="{ item }"  >
        <v-row style="width: 100%;"  v-if="item.status=='pending'&&item.active==true">

          <v-btn
              v-if="$store.getters.hasDeletePermission('request')"
              icon
              color="blue"

              @click="deleteItem()"
          >
            <v-icon size="30" color="red" class="ma-5">mdi-delete</v-icon>
          </v-btn>
          <v-btn
              v-if="$store.getters.hasChangePermission('request')"
              depressed
              color="green"
              dark
              class="rounded-xl ml-1"
              @click="editItem(true)"
          >
            Approve
          </v-btn>

          <v-btn
              v-if="$store.getters.hasChangePermission('request')"
              depressed
              dark
              color="error"
              class="rounded-xl mr-1"
              @click="editItem(false)"
          >
            Rejecte
          </v-btn>

        </v-row>
        <v-chip
            v-else-if="item.active==false"
            :color="getColor(false)"
            dark
        >
        محذوف
        </v-chip>
      </template>

    </v-data-table>
    <DeleteItemDialog v-if="$store.getters.hasDeletePermission('request')" :url-name="'/request/delete'" :delete-controller.sync="deleteController" :item-delete="itemSelected" :token="$store.state.token"/>
    <AproveDialog v-if="$store.getters.hasChangePermission('request')" :url-name="'/request/update'" :dialog-controller.sync="dialogController" :is-aprove="isAprove" :item-delete="itemSelected" :token="$store.state.token"/>

  </v-card>
</template>
<script>
import Accountview from "@/components/dialogs/PackageRequestview";
import createAccount from "@/components/createActivation";
import DeleteItemDialog from "@/components/dialogs/DeleteItemDialog";
import AproveDialog from "@/components/dialogs/AproveDialog";
import Loading from "@/components/Loading";
export default {
  components:{
    Accountview,
    createAccount,
    AproveDialog,
    DeleteItemDialog,
    Loading,
  },
  props: ['headers',"contents"],

  data: () => ({
    deleteController:false,
    dialogController:false,
    groups:[],
    isloading: false,
    isAprove:false,
    search: "",
    sortDesc:true,
    qrDialog: false,
    selectValue:"",
    select: "",
    isDialog:false,
    itemSelected:null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,

  }),

  methods: {
   async setSelected(value){
      await this.getAll(value);
      this.selectValue= value;
    },
    openDialogNew(){
      this.dialog =false;
      this.isDialog= true;
    },

    async handleClick(value){
      this.itemSelected = value;
      this.qrDialog = true;
    },
    getColor(value){
     console.log(value);
      // approved rejected
      if (value==false) return 'red'
      if (value=="pending") return 'orange'
      else if (value=='approved') return 'green'
      else return 'red'
    },
    close(){
     this.dialog=false;
     this.deleteController = false;
    },
    deleteItem(){
     this.deleteController = true;
    },
    editItem(value){
     this.isAprove = value;
      this.dialogController = true;

    }

  },  mounted() {
    this.$root.$on('closeView', () => {
      this.close();
    });
  }

};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(202, 202, 202, 0.986);
}


</style>
